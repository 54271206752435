<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-0 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>
    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
          md="8"
          class="px-1"
          order="2"
          order-md="1"
        >
          <b-form-group
            label="Search"
            label-for="filter_search"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filter_search"
              v-model="tableEvent.filter.search"
              type="text"
              size="sm"
              placeholder="search here"
              debounce="1000"
              autocomplete="off"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="6"
          md="2"
          class="px-1"
          order="1"
          order-md="2"
        >
          <b-form-group
            label="Date From"
            label-for="filter_date_from"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filter_date_from"
              v-model="tableEvent.filter.date_from"
              size="sm"
              type="date"
              :max="tableEvent.filter.date_to"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="6"
          md="2"
          class="px-1"
          order="1"
          order-md="2"
        >
          <b-form-group
            label="Date To"
            label-for="filter_date_to"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filter_date_to"
              v-model="tableEvent.filter.date_to"
              size="sm"
              type="date"
              :min="tableEvent.filter.date_from"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <b-row class="mt-1">
        <b-col
          cols="12"
          class="px-1"
        >
          <b-table
            ref="tableEvent"
            hover
            small
            striped
            bordered
            responsive
            show-empty
            :stacked="isMobile()"
            :per-page="tableEvent.perPage"
            :current-page="tableEvent.currentPage"
            :items="tableEventProvider"
            :fields="tableEvent.fields"
            :sort-by.sync="tableEvent.sortBy"
            :sort-desc.sync="tableEvent.sortDesc"
            :sort-direction="tableEvent.sortDirection"
            :filter="tableEvent.filter"
            :filter-included-fields="tableEvent.filterOn"
            :busy="tableEvent.busy"
          >
            <template #cell(index)="row">
              {{ tableEvent.currentPage * tableEvent.perPage - tableEvent.perPage + (row.index + 1) }}
            </template>

            <template #cell()="row">
              <div class="text-wrap text-md-nowrap">
                {{ row.value }}
              </div>
            </template>

            <template #cell(active)="row">
              {{ Number(row.value) === 1 ? 'Yes' : 'No' }}
            </template>

            <template #cell(payment.amount)="row">
              <div
                class="text-md-nowrap text-right"
                style="min-width: 100px !important;"
              >
                {{ row.value }}
              </div>
            </template>

            <template #cell(action)="{ item }">
              <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
                <b-button
                  size="sm"
                  variant="primary"
                  @click="onView(item)"
                >
                  Show Details
                </b-button>
              </div>
            </template>

            <template #table-busy>
              <div class="text-center py-5">
                <b-icon
                  icon="stopwatch"
                  font-scale="5"
                  animation="cylon"
                />
                <p class="h3 py-2">
                  <strong>Loading . . .</strong>
                </p>
              </div>
            </template>

          </b-table>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="mt-1 mb-2 px-1"
        >
          <b-select
            v-model="tableEvent.perPage"
            :options="tableEvent.pageOptions"
            size="sm"
            class="w-100 w-md-25"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
        >
          <b-pagination
            v-model="tableEvent.currentPage"
            pills
            last-number
            first-number
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
            :total-rows="tableEvent.totalRows"
            :per-page="tableEvent.perPage"
          />
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="modal-budgets"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      modal-class="fullscreen"
    >
      <b-tabs
        active-nav-item-class="font-weight-bold text-uppercase text-success"
        content-class="scrollable"
      >
        <b-tab class="py-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="fad fa-file-invoice h4 px-1" />
              <b class="is-tab-active">Details</b>
            </div>
          </template>
          <b-row class="px-5">
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Organization"
                label-for="wh_organization"
                label-class="font-weight-bolder"
                class="mb-2"
              >
                <b-input
                  id="wh_organization"
                  v-model="webhook.organization"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mb-3"
            />
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Partner Reference"
                label-for="wh_partner_reference"
                label-class="font-weight-bolder"
                class="mb-2"
              >
                <b-input
                  id="wh_partner_reference"
                  v-model="webhook.partner_reference"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Provider Reference"
                label-for="wh_provider_reference"
                label-class="font-weight-bolder"
                class="mb-2"
              >
                <b-input
                  id="wh_provider_reference"
                  v-model="webhook.provider_reference"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mb-3"
            />
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Status"
                label-for="wh_status"
                label-class="font-weight-bolder"
                class="mb-2"
              >
                <b-input
                  id="wh_status"
                  v-model="webhook.status"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Completed At"
                label-for="wh_completed_at"
                label-class="font-weight-bolder"
                class="mb-2"
              >
                <b-input
                  id="wh_completed_at"
                  v-model="webhook.completed_at"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="URL"
                label-for="wh_url"
                label-class="font-weight-bolder"
                class="mb-2"
              >
                <b-input
                  id="wh_url"
                  v-model="webhook.url"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Attempts"
                label-for="wh_attempts"
                label-class="font-weight-bolder"
                class="mb-2"
              >
                <b-input
                  id="wh_attempts"
                  v-model="webhook.attempt"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab class="py-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="fad fa-brackets-square h4 px-1" />
              <b class="is-tab-active">Webhook Payload</b>
            </div>
          </template>
          <b-row class="px-5">
            <b-col
              cols="8"
            >
              <b-form-group
                label="Headers"
                label-class="font-weight-bolder"
                class="mb-2"
              >
                <vue-json-pretty
                  :data="webhook.headers"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="8"
            >
              <b-form-group
                label="Payload"
                label-class="font-weight-bolder"
                class="mb-2"
              >
                <vue-json-pretty
                  :data="webhook.payload"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab class="py-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="fad fa-brackets-curly h4 px-1" />
              <b class="is-tab-active">User Payload</b>
            </div>
          </template>
          <b-row class="px-5">
            <b-col
              cols="8"
            >
              <b-form-group
                label="Payload"
                label-class="font-weight-bolder"
                class="mb-2"
              >
                <vue-json-pretty
                  :data="webhook.user_payload"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab class="py-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="fad fa-file h4 px-1" />
              <b class="is-tab-active">Client Response</b>
            </div>
          </template>
          <b-row class="px-5">
            <b-col
              cols="12"
            >
              {{ webhook.response }}
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close <span class="d-none d-md-inline-block">Window</span>
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
// import QUERY from 'lodash'
import { core } from '@/config/pluginInit'
import { SAdminWebhook } from '@/services'
import formatter from '@/mixins/formatter'
import Ripple from 'vue-ripple-directive'
import misc from '@/mixins/misc'

export default {
  name: 'AdminWebhookEvents',

  middleware: ['auth', 'admin'],

  directives: {
    Ripple
  },

  mixins: [misc, formatter],

  metaInfo: () => ({
    title: 'Events'
  }),

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      webhook: {
        organization: null,
        partner_reference: null,
        provider_reference: null,
        status: null,
        completed_at: null,
        headers: null,
        payload: null,
        user_payload: null,
        response: null,
        attempt: 1,
        url: null
      },
      tableEvent: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null,
          date_from: null,
          date_to: null
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 11, key: 'action', class: 'text-center min-w-150px' },
          { mobile: 1, key: 'created_at', class: 'min-w-200px', formatter: this.dateTimeFormatter, sortable: true },
          { mobile: 2, key: 'user.organization', label: 'Organization', class: 'min-w-250px' },
          { mobile: 3, key: 'disbursement.client_reference', label: 'Partner Reference', class: 'min-w-350px' },
          { mobile: 4, key: 'disbursement.reference_number', label: 'Reference Number', class: 'min-w-350px' },
          { mobile: 10, key: 'attempt', class: 'text-center' }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async tableEventProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableEvent.busy = true
      return await SAdminWebhook.getEvents(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search,
          filter_date_from: filter.date_from,
          filter_date_to: filter.date_to
        })
      ).then(({ data }) => {
        this.tableEvent.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableEvent.busy = false
      })
    },

    onView (webhook) {
      this.webhook.organization = webhook.user.organization

      this.webhook.partner_reference = webhook.disbursement.client_reference
      this.webhook.provider_reference = webhook.disbursement.reference_number
      this.webhook.status = webhook.disbursement.status
      this.webhook.completed_at = this.dateTimeFormatter(webhook.disbursement.completed_at)

      this.webhook.url = webhook.url
      this.webhook.attempt = webhook.attempt

      this.webhook.headers = webhook.headers
      this.webhook.payload = webhook.payload
      this.webhook.user_payload = webhook.disbursement.user_payload
      this.webhook.response = webhook.response
      this.$bvModal.show('modal-budgets')
    }
  }
}
</script>
